import React, { useEffect, useState } from "react";
import styles from "../../styles/pages/trading-assistant/index.module.sass";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Spin } from "antd";
import { getTradeAssistant } from "../../redux/trading-assistant/actions";
import TradeAssistantTable from "../tables/TradeAssistantTable";
import RiskAppetite from "../../shared/RiskAppetite";
import { updateProfile } from "../../redux/profile/actions";
import { FaBell, FaRobot } from "react-icons/fa";
import { useNavigate } from "react-router";
import useServiceStatus from "../../hooks/useServiceStatus";
import TradeAssistantCards from "./TradeAssistantCards";

function Assistant({ portfolio, market, aiMode }) {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [riskAmount, setRiskAmount] = useState("3");
  const [showTable, setShowTable] = useState(false);

  const {
    main: { userData },
    tradingAssistant: { tradeAssistant, allTradeSuggestionLoading },
    profile: { profileData, updateLoading: updateProfileLoading },
  } = useSelector((state) => state);

  const navigate = useNavigate();
  const { isActive } = useServiceStatus("aiAssistant");

  const { ASSISTANT_SUGGESTIONS } = tradeAssistant;

  const handleCancelModal = () => {
    setShowModal(false);
  };
  const handleOkModal = () => {
    setShowModal(false);
  };

  const handleRiskChange = (e) => setRiskAmount(e.target.value);

  const updateRiskAppetite = async () => {
    // e.preventDefault();
    let attribute_names = ["ai_assistant_risk_appetite"];
    await dispatch(
      updateProfile(
        userData?.email,
        userData["custom:custom_username"],
        attribute_names,
        [riskAmount],
      ),
    );

    setShowModal(false);

    dispatch(
      getTradeAssistant(
        userData?.["cognito:username"],
        userData?.["custom:custom_username"],
        portfolio,
        market,
        isActive,
        aiMode,
      ),
    );
  };

  useEffect(() => {
    if (profileData && profileData?.email) {
      setRiskAmount(profileData?.ai_assistant_risk_appetite);
    }
  }, [profileData]);

  return (
    <div>
      <div className={styles.tradingHeader}>
        <div>
          <div className={styles.assistantTitle}>
            <h3>
              <strong>AI Assistant</strong>
            </h3>
            <div className={styles.otherSignalStatus}>
              {allTradeSuggestionLoading && ASSISTANT_SUGGESTIONS && (
                <Spin
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: ".2rem",
                  }}
                  size={"small"}
                  tip={"Fetching more signals"}
                />
              )}
            </div>
          </div>
          <p className={styles.mobileMsg}>
            AI-informed manual trading. Alerts for strong signals. Alerts On.
            For automation, Use Bot.
          </p>
          <p className={styles.desktopMsg}>
            Experience manual trading guided by AI. Receive alerts for the
            strongest signals. Activate Alerts. For a hands-off approach, opt
            for our automated trading bot. Use Trading Bot.
          </p>
        </div>
        <div className={styles.actionBtns}>
          <Button
            className={styles.alertBtn}
            onClick={() => navigate("/alert-system")}
          >
            <FaBell />
            Alerts On
          </Button>

          <Button
            className={styles.createBotBtn}
            onClick={() => navigate("/bot-automation")}
          >
            <FaRobot />
            Use Bot
          </Button>
        </div>
      </div>
      <div className={styles.portfolioTable}>
        {/*<TradeAssistantTable*/}
        {/*  data={ASSISTANT_SUGGESTIONS}*/}
        {/*  height={600}*/}
        {/*  showTable={showTable}*/}
        {/*  setShowTable={setShowTable}*/}
        {/*/>*/}
        <TradeAssistantCards data={ASSISTANT_SUGGESTIONS} />
      </div>

      <Modal
        width={500}
        open={showModal}
        onCancel={handleCancelModal}
        onOk={handleOkModal}
        footer={null}
        centered
      >
        <div
          style={{
            height: "350px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "2rem 0",
          }}
        >
          <div style={{ marginBottom: "1rem" }}>
            <h3>
              <strong>Change Your Risk Appetite:</strong>
            </h3>
            <p>Customize Your Trading Experience</p>
          </div>
          <RiskAppetite
            style={{ display: "grid", gridGap: "1rem" }}
            amount={riskAmount}
            changeHandler={handleRiskChange}
          />
          <Button
            onClick={updateRiskAppetite}
            style={{
              alignSelf: "end",
              backgroundColor: "#0C4CFC",
              color: "white",
              marginTop: "1rem",
            }}
            loading={updateProfileLoading}
          >
            Save
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default Assistant;
