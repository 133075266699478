import { useDispatch, useSelector } from "react-redux";
import styles from "../../../../styles/shared/trade-form/TradeModal.module.css";
import { Checkbox } from "antd";
import LimitOrderTriggerPrice from "./LimitOrderTriggerPrice";
import { changeLimitStatus } from "../../../../redux/trade-form/tradeFormSlice";
import ApplyAiSuggestion from "../ApplyAiSuggestion";

function SetLimitForBuyOrder() {
  // { setLimit, checkedLimit }
  const {
    tradeForm: {
      finalTradeType,
      isLimitSet,
      // { stopLoss, takeProfit },
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  // const { takeProfit, stopLoss } = checkedLimit;

  const changeSetting = () => dispatch(changeLimitStatus());
  //     (limitType) => {
  //   // const checked = e.target.checked;
  //   return () => dispatch(changeLimitStatus(limitType));
  //   // setLimit((prevLimit) => {
  //   //   return { ...prevLimit, [limitType]: checked };
  //   // });
  // };

  if (finalTradeType !== "buy") return null;

  return (
    <div className={styles.limitOptions}>
      <div className={styles.limitBox}>
        <div className={styles.limitBoxHeader}>
          <Checkbox checked={isLimitSet} onChange={changeSetting} />
          Set limit Order
          <ApplyAiSuggestion field="limits" />
        </div>
        <div className={styles.limitBoxBody}>
          <div className={styles.amountInputs}>
            <div className={styles.inputBox}>
              <label>Stop Loss</label>
              <LimitOrderTriggerPrice
                limitType="low"
                title={false}
                style={{ marginTop: 0 }}
              />
            </div>

            <div className={styles.inputBox}>
              <label>Take Profit</label>
              <LimitOrderTriggerPrice
                limitType="high"
                title={false}
                style={{ marginTop: 0 }}
              />
            </div>
          </div>

          {!isLimitSet && <div className={styles.overlayMask}></div>}
        </div>
      </div>

      {/*<div className={`${styles.limitBox}`}>*/}
      {/*  <div className={styles.limitBoxHeader}>*/}
      {/*    <Checkbox checked={stopLoss} onChange={changeSetting("stopLoss")} />*/}
      {/*    Set Stop loss*/}
      {/*  </div>*/}
      {/*  <div className={styles.limitBoxBody}>*/}
      {/*    <LimitOrderTriggerPrice limitType="low" title={false} />*/}
      {/*    {!stopLoss && <div className={styles.overlayMask}></div>}*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/*<div className={`${styles.limitBox}`}>*/}
      {/*  <div className={styles.limitBoxHeader}>*/}
      {/*    <Checkbox*/}
      {/*      checked={takeProfit}*/}
      {/*      onChange={changeSetting("takeProfit")}*/}
      {/*    />*/}
      {/*    Set take profit*/}
      {/*  </div>*/}

      {/*  <div className={styles.limitBoxBody}>*/}
      {/*    <LimitOrderTriggerPrice limitType="high" title={false} />*/}

      {/*    {!takeProfit && <div className={styles.overlayMask}></div>}*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
}

export default SetLimitForBuyOrder;
