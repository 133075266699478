import styles from "../../../styles/Components/integration/portfolio/ExchangeNewPortfolioTable.module.css";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { formatAmount, formatPrice } from "../../../utility/helper";
import { checkIsCoin } from "../../../utility/tradeHelper";
import { FaExchangeAlt } from "react-icons/fa";
import { get200Coins } from "../../../redux/trading-assistant/actions";
import { Table } from "antd";
import ExchangePortfolioTableExpandedRow from "./ExchangePortfolioTableExpandedRow";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import TradeButton from "../../../shared/trade-form/TradeButton";

function ExchangeNewPortfolioTable({ exchange, data, loading }) {
  const {
    main: { userData },
    tradingAssistant: {
      _200Coins: { top_200_coin },
      get200CoinsLoading,
    },
  } = useSelector((state) => state);

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const [tableData, setTableData] = useState([]);

  const dispatch = useDispatch();
  const onRowClick = (record) => {
    if (expandedRowKeys.includes(record.key)) {
      return setExpandedRowKeys([]);
    }
    setExpandedRowKeys([record.key]);
  };

  const columns = [
    {
      title: "Balance in USDT",
      dataIndex: "balance",
      key: "balance",
      align: "center",
      responsive: ["xs", "sm"],
      width: 80,
      render: (text) => (
        <div className={styles.tableContent}>
          <h4 className={styles.coin}>
            <img
              src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${
                text.asset.includes("/") ? text.asset.slice("/")[0] : text.asset
              }.png`}
              alt={text}
              className={styles.coinIcon}
            />
            {text?.asset}
          </h4>
          <div className={styles.balances}>
            <span>{formatAmount(text.balance)}</span>
            <p>{formatAmount(text.amount)} USD</p>
          </div>
        </div>
      ),
    },

    {
      title: <span className={styles.title}>Price</span>,
      dataIndex: "price",
      key: "price",
      width: 60,
      responsive: ["sm"],
      align: "center",
      render: (text) => (
        <span className={`${styles.tableContent}`}>${formatPrice(text)}</span>
      ),
    },
    {
      title: <span className={styles.title}>PNL</span>,
      dataIndex: "pnl",
      key: "pnl",
      width: 60,
      responsive: ["xs", "sm"],
      align: "center",
      render: ({ value, percent }) => (
        <div className={`${styles.pnlInfo}`}>
          {!value && !percent ? (
            "-"
          ) : (
            <>
              <span
                className={`${styles.pnl} ${
                  Number(value) > 0 ? styles.positive : ""
                } ${Number(value) < 0 ? styles.negative : ""}`}
              >
                {value || "-"}
                {value && "USDT"}
              </span>
              {percent && (
                <span
                  className={`${styles.pnlPercent}  ${
                    Number(percent) > 0 ? styles.positive : ""
                  } ${Number(percent) < 0 ? styles.negative : ""}`}
                >
                  ({percent})%
                </span>
              )}
            </>
          )}
        </div>
      ),
    },
    {
      title: <span className={styles.title}>Buy/Sell Asset</span>,
      dataIndex: "addToPortfolio",
      key: "addToPortfolio",
      width: 70,
      responsive: ["xs", "sm"],
      align: "center",
      render: (text) =>
        !top_200_coin ? (
          "..."
        ) : checkIsCoin(top_200_coin, text?.assetName) ? (
          <>
            <TradeButton
              btnClassName={styles.addBtn}
              data={{ coin: text?.assetName }}
            >
              <FaExchangeAlt />
            </TradeButton>
          </>
        ) : (
          "-"
        ),
    },
  ];

  useEffect(() => {
    if (!loading) {
      setTableData(
        data
          ?.sort((a, b) => (b.assetName === "USDT" ? 1 : -1))
          ?.map((item, index) => {
            return {
              key: index,
              asset: item?.assetName,
              availableBalance: item?.availableBalance,
              addToPortfolio: item,
              usdAmount: item?.availableBalanceUSD,
              staking: item?.stakingBalance,
              stopLoss: item?.stop_loss,
              takeProfit: item?.take_profit,
              balance: {
                asset: item?.assetName,
                balance: item?.availableBalance,
                amount: item?.availableBalanceUSD,
              },
              price: item?.current_price,
              pnl: {
                value: item?.pnl,
                percent: item?.pnl_percent,
              },
            };
          }),
      );
    }
  }, [data, loading]);

  useEffect(() => {
    if (
      !top_200_coin &&
      userData?.["cognito:username"] &&
      !get200CoinsLoading
    ) {
      dispatch(
        get200Coins(
          userData?.["cognito:username"],
          userData?.["custom:custom_username"],
        ),
      );
    }
  }, [dispatch, get200CoinsLoading, top_200_coin, userData]);

  useEffect(() => {
    setExpandedRowKeys([]);
  }, [exchange]);

  return (
    <div>
      <div className={styles.tableContainer}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={tableData}
          pagination={false}
          height={500}
          scroll={{ y: 500 }}
          className={`${styles.portfolioTable} expanded-table`}
          onRow={(record) => ({
            onClick: () => onRowClick(record),
          })}
          expandable={{
            expandedRowRender: (record) => (
              <ExchangePortfolioTableExpandedRow data={record} />
            ),
            rowExpandable: (record) => record.asset !== "USDT",
            expandedRowKeys,
            expandIcon: ({ expanded, onExpand, record }) => {
              if (record.asset === "USDT") return "";
              if (expanded) {
                return (
                  <IoChevronUp
                    className={styles.expandIcon}
                    size={20}
                    onClick={(e) => {
                      onRowClick(record);
                    }}
                  />
                );
              } else {
                return (
                  <IoChevronDown
                    className={styles.expandIcon}
                    size={20}
                    onClick={(e) => {
                      onRowClick(record);
                    }}
                  />
                );
              }
            },
          }}
        />
      </div>
    </div>
  );
}

export default ExchangeNewPortfolioTable;
