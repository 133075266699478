import axios from "axios";
import { axiosErrorHandler } from "../../utility/action-helper";

const initialState = {
  step: 1,
  targetExchange: "",
  suggestedCoin: "",
  targetCoin: "",
  tradeType: "",
  targetPortfolio: [],
  targetPortfolioStatus: "",
  targetFee: "",
  targetPortfolioError: "",
  assetPrice: "",
  assetPriceStatus: "",
  assetPriceError: "",
  finalTradeType: "",
  tradeNativeAmount: "",
  tradeUsdAmount: "",
  tradeAmountPercentage: 50,
  triggerPrice: "",
  triggerPricePercentage: "",
  isLimitSet: true,
  slTriggerPrice: "",
  tpTriggerPrice: "",
  aiSuggestion: {},
  aiSuggestionStatus: "",
  aiSuggestionError: "",
  orderStatus: "",
  orderResponse: {},
  orderErrorMessage: {},
};

// action types:
// set-target-exchange
// set-target-coin
// set-trade-type

export const tradeFormReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "change-step":
      return {
        ...state,
        step: payload,
      };

    case "set-target-exchange":
      if (state.targetCoin) {
        state.targetCoin = "";
      }
      clearState(state);

      return {
        ...state,
        aiSuggestionStatus: "",
        aiSuggestion: {},
        aiSuggestionError: "",
        targetExchange: payload,
      };

    case "set-suggested-coin":
      return {
        ...state,
        suggestedCoin: payload,
      };

    case "set-target-coin":
      clearState(state);

      return {
        ...state,
        aiSuggestionStatus: "",
        aiSuggestion: {},
        aiSuggestionError: "",
        targetCoin: payload,
      };

    case "set-trade-type":
      clearState(state);

      return {
        ...state,
        tradeType: payload,
        finalTradeType: payload,
      };

    case "set-final-trade-type":
      return {
        ...state,
        finalTradeType: payload,
      };

    case "set-trade-amount":
      return {
        ...state,
        tradeNativeAmount: payload?.native,
        tradeUsdAmount: payload?.usd,
        tradeAmountPercentage: payload?.percentage,
      };

    case "set-trigger-price":
      return {
        ...state,
        triggerPrice: payload,
      };

    case "set-stop-loss-trigger-price":
      return {
        ...state,
        slTriggerPrice: payload,
      };

    case "set-take-profit-trigger-price":
      return {
        ...state,
        tpTriggerPrice: payload,
      };

    case "change-limit-status":
      return {
        ...state,
        isLimitSet: !state.isLimitSet,
      };

    case "get-target-portfolio-request":
      return {
        ...state,
        targetPortfolioStatus: "loading",
        targetPortfolioError: "",
        targetPortfolio: [],
      };
    case "get-target-portfolio-success":
      return {
        ...state,
        targetPortfolioStatus: "success",
        targetPortfolio: payload?.portfolio,
        targetFee: Number(payload?.transaction_fee),
      };
    case "get-target-portfolio-error":
      return {
        ...state,
        targetPortfolioError: payload,
        targetPortfolioStatus: "error",
      };
    case "get-target-portfolio-canceled":
      return {
        ...state,
        targetPortfolioStatus: "loading",
        targetPortfolio: [],
        targetFee: "",
        targetPortfolioError: "",
      };

    case "get-asset-price-request":
      return {
        ...state,
        // assetPrice: "",
        assetPriceStatus: "loading",
        assetPriceError: "",
      };
    case "get-asset-price-success":
      return {
        ...state,
        assetPrice: payload,
        assetPriceStatus: "success",
      };
    case "get-asset-price-error":
      return {
        ...state,
        assetPriceError: payload,
        assetPriceStatus: "error",
        assetPrice: "",
      };

    case "get_suggestion_request":
      return {
        ...state,
        aiSuggestion: {},
        aiSuggestionStatus: "loading",
        assetPriceError: "",
      };
    case "get_suggestion_success":
      if (payload?.prediction !== "Hold") {
        const type = payload?.prediction?.toLowerCase();
        state.tradeType = type;
        state.finalTradeType = type;
      }

      return {
        ...state,
        aiSuggestion: payload,
        aiSuggestionStatus: "success",
      };
    case "get_suggestion_error":
      return {
        ...state,
        aiSuggestionError: payload,
        aiSuggestionStatus: "error",
      };
    case "get_suggestion_canceled":
      return {
        ...state,
        aiSuggestionStatus: "canceled",
      };

    case "order-request":
      return {
        ...state,
        orderStatus: "loading",
        orderResponse: {},
        orderErrorMessage: "",
      };
    case "order-success":
      return {
        ...state,
        orderStatus: "success",
        orderResponse: payload,
      };
    case "order-error":
      return {
        ...state,
        orderStatus: "error",
        orderErrorMessage: payload,
      };

    case "reset-form":
      return initialState;

    default:
      return state;
  }
};

export const changeStep = (step) => (dispatch) => {
  return dispatch({ type: "change-step", payload: step });
};

export const setTargetExchange = (exchange) => (dispatch) => {
  return dispatch({ type: "set-target-exchange", payload: exchange });
};

export const setSuggestedCoin = (suggestedCoin) => (dispatch) => {
  return dispatch({ type: "set-suggested-coin", payload: suggestedCoin });
};

export const setTargetCoin = (coin) => (dispatch) => {
  return dispatch({ type: "set-target-coin", payload: coin });
};

export const setTradeType = (type) => (dispatch) => {
  return dispatch({ type: "set-trade-type", payload: type });
};

export const resetForm = () => (dispatch) => {
  return dispatch({ type: "reset-form" });
};

export const setFinalTradeType = (type) => (dispatch) => {
  dispatch({ type: "set-final-trade-type", payload: type });
};

export const setTradeSize = (native, usd, percentage) => (dispatch) => {
  dispatch({ type: "set-trade-amount", payload: { native, usd, percentage } });
};

export const setLimitOrderTriggerPrice = (value) => (dispatch) => {
  return dispatch({ type: "set-trigger-price", payload: value });
};

export const setStopLossTriggerPrice = (value) => (dispatch) => {
  return dispatch({ type: "set-stop-loss-trigger-price", payload: value });
};

export const setTakeProfitTriggerPrice = (value) => (dispatch) => {
  return dispatch({ type: "set-take-profit-trigger-price", payload: value });
};

export const changeLimitStatus = () => (dispatch) => {
  return dispatch({ type: "change-limit-status" });
};

export const getTargetPortfolio =
  (user, email, exchange_id, signal = null, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: "get-target-portfolio-request" });

    const data = {
      mode: "get_portfolio",
      user,
      email,
      exchange_id,
    };

    if (retryCount > 0) {
      data.use_more_caching = "True";
    }

    await axios
      .post(
        "https://ydq39f2t8b.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        data,
        { signal },
      )
      .then((res) => {
        dispatch({
          type: "get-target-portfolio-success",
          payload: res.data,
        });
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          dispatch({ type: "get-target-portfolio-canceled" });
          console.log("Request canceled:", err.message);
        } else {
          axiosErrorHandler(
            err,
            retryCount,
            dispatch,
            () =>
              getTargetPortfolio(
                user,
                email,
                exchange_id,
                signal,
                retryCount + 1,
              ),
            "get-target-portfolio-error",
            user,
            email,
            "get_portfolio",
          );
        }
      });
  };

export const getAssetPrice =
  (user, email, exchange_id, assets, retryCount = 0) =>
  async (dispatch) => {
    const asset = assets?.split("/")?.[0];
    // const exchange = exchange_id === "cryptocrispy" ? "binance" : exchange_id;
    const exchange = exchange_id;

    dispatch({ type: "get-asset-price-request" });

    await axios
      .post(
        "https://ydq39f2t8b.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        {
          mode: "get_prices",
          user,
          email,
          exchange_id: exchange,
          assets: [asset],
        },
      )
      .then((res) => {
        dispatch({
          type: "get-asset-price-success",
          payload: res.data?.[asset],
        });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => getAssetPrice(user, email, exchange_id, assets, retryCount + 1),
          "get-asset-price-error",
          user,
          email,
          "get_prices",
        );
      });
  };

// FUNCTIONS TO MANAGE STATE
const clearState = (state) => {
  resetPriceData(state);
  resetTriggerPrice(state);
  resetTradeAmount(state);
  resetLimitOrderTriggerPrice(state);
};

const resetPriceData = (state) => {
  if (state.assetPrice) {
    state.assetPrice = "";
    state.assetPriceStatus = "";
    state.assetPriceError = "";
  }
};

const resetTradeAmount = (state) => {
  if (state.tradeUsdAmount) {
    state.tradeUsdAmount = "";
    state.tradeNativeAmount = "";
  }
};

const resetTriggerPrice = (state) => {
  if (state.triggerPrice) {
    state.triggerPrice = "";
    state.triggerPricePercentage = "";
  }
};

const resetLimitOrderTriggerPrice = (state) => {
  if (state.tpTriggerPrice) {
    state.tpTriggerPrice = "";
    state.slTriggerPrice = "";
  }
};

export const getAISuggestion =
  (
    user,
    email,
    exchange_id,
    symbolName,
    do_backtest = false,
    signal,
    retryCount = 0,
  ) =>
  async (dispatch) => {
    const coin = symbolName?.split("/USDT")?.[0];

    dispatch({
      type: "get_suggestion_request",
      payload: { symbol: coin, exchange_id },
    });

    if (symbolName?.includes("STETH")) {
      exchange_id = "bybit";
    }

    let data = {
      action: "inference",
      exchange_id,
      symbol: symbolName?.includes("USDT") ? symbolName : `${symbolName}/USDT`,
      user,
      email,
    };

    if (do_backtest) {
      data.do_backtest = "True";
    }

    await axios
      .post(
        "https://akqyv95sd7.execute-api.ap-southeast-2.amazonaws.com/dev/pivot",
        data,
        { signal }, // Pass the signal here
      )
      .then((res) => {
        dispatch({
          type: "get_suggestion_success",
          payload: res?.data,
        });
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          dispatch({ type: "get_suggestion_canceled" });
          console.log("Request canceled:", err.message);
        } else {
          axiosErrorHandler(
            err,
            retryCount,
            dispatch,
            () =>
              getAISuggestion(
                user,
                email,
                exchange_id,
                symbolName,
                do_backtest,
                signal,
                retryCount + 1,
              ),
            "get_suggestion_error",
            user,
            email,
            "inference",
          );
        }
      });
  };

export const placeOrder =
  (
    user,
    email,
    trade_type,
    exchange_id,
    asset,
    amount,
    trigger_price,
    trigger_price2 = "",
    retryCount = 0,
  ) =>
  async (dispatch) => {
    dispatch({ type: "order-request" });

    const formattedAsset =
      exchange_id === "swyftx"
        ? asset?.split("/USDT")?.[0]
        : asset?.includes("/USDT")
          ? asset
          : asset + "/USDT";

    const data = {
      mode: "order_trade",
      exchange_id,
      user,
      email,
      asset: formattedAsset,
      trade_type,
      freeze_limit_order: "False",
      amount: `${amount}`,
      trigger_price: `${trigger_price * 100}`,
      currency: exchange_id === "swyftx" ? "USDT" : "",
    };

    if (trade_type === "buy" || trade_type === "sell") {
      data.trigger_price = "";
    }

    if (trade_type === "buy_with_oco") {
      data.trigger_price2 = `${trigger_price2 * 100}`;
      data.amount_usdt = `${amount}`;
    }

    await axios
      .post(
        "https://ydq39f2t8b.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        data,
      )
      .then((res) => {
        console.log(res?.data);
        dispatch({ type: "order-success", payload: res?.data });
      })
      .catch((err) => {
        console.log(err);
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            placeOrder(
              user,
              email,
              trade_type,
              exchange_id,
              asset,
              amount,
              trigger_price,
              (trigger_price2 = ""),
              retryCount + 1,
            ),
          "order-error",
          user,
          email,
          "place-order",
        );
      });
  };
