import React, { useMemo, useState } from "react";
import { Tabs } from "antd";
import styles from "../../../styles/shared/trade-form/TradeModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import InstantOrderOptions from "./trade-size/InstantOrderOptions";
import { setFinalTradeType } from "../../../redux/trade-form/tradeFormSlice";
import LimitOrderOptions from "./trade-size/LimitOrderOptions";
import ApplyAiSuggestion from "./ApplyAiSuggestion";

function TradeTypes() {
  const {
    tradeForm: { tradeType, finalTradeType },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(
    finalTradeType === "buy" || finalTradeType === "sell" ? "instant" : "limit",
  );

  const handleChangeTab = (tab) => {
    setActiveTab(tab);

    if (activeTab !== tab) {
      if (tradeType === "buy") {
        dispatch(setFinalTradeType(tab === "instant" ? "buy" : "buy_db"));
      }
      if (tradeType === "sell") {
        dispatch(setFinalTradeType(tab === "instant" ? "sell" : "sell_sl"));
      }
    }
  };

  const tabItems = useMemo(() => {
    const sellOptions = [
      {
        key: "limit",
        label: "Limit Order",
        children: <LimitOrderOptions />,
      },
    ];
    const buyOptions = [
      {
        key: "limit",
        label: "Limit Order",
        children: <LimitOrderOptions />,
      },
    ];

    const items = [
      {
        key: "instant",
        label: (
          <div>
            Instant Order <ApplyAiSuggestion field="tradeType" />
          </div>
        ),
        children: <InstantOrderOptions />,
      },
    ];

    return tradeType === "sell"
      ? [...items, ...sellOptions]
      : [...items, ...buyOptions];
  }, [tradeType]);

  return (
    <div className={styles.box}>
      <Tabs items={tabItems} onChange={handleChangeTab} activeKey={activeTab} />
    </div>
  );
}

export default TradeTypes;
