/** @format */

import React, { useEffect, useRef, useState } from "react";
import { Card, Spin, Tooltip, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { FiRefreshCcw } from "react-icons/fi";
import { errorHandler, formatPrice, refreshLimit } from "../../utility/helper";
import { MdOutlineUpdate } from "react-icons/md";
import axios from "axios";
import styles from "../../styles/Components/trading-assistant/tradeAssistantCards.module.sass";
import {
  FaArrowCircleDown,
  FaArrowCircleUp,
  FaBrain,
  FaCoins,
  FaPauseCircle,
} from "react-icons/fa";
import { RiExchangeFundsLine } from "react-icons/ri";
import { getRelativeTime } from "../../utility/formatter-helper";
import { getLiveSuggestion } from "../../redux/live-suggestion/liveSuggestionSlice";
import LiveSuggestionModal from "../../shared/live-suggestion/LiveSuggestionModal";
import refreshIcon from "../../asset/Icons/notification(1).gif";
import AIAssistantServiceActivationModal from "./AIAssistantServiceActivationModal";
import HideContentIcon from "../mini-app/shared/HideContentIcon";
import AiAssistantTableActionButtons from "./assistant/AiAssistantTableActionButtons";
import AssetDisplay from "../../ui/AssetDisplay";
import OtherCoins from "../../shared/other-coins-signal/OtherCoins";
import TradeButton from "../../shared/trade-form/TradeButton";

const TradeAssistantCards = ({ data = [] }) => {
  const [priceRefreshTime, setPriceRefreshTime] = useState(60);
  const [showServiceActivationModal, setShowServiceActivationModal] =
    useState(false);
  const [showLiveSuggestionModal, setShowLiveSuggestionModal] = useState(false);
  const [liveSuggestionTarget, setLiveSuggestionTarget] = useState(null);
  const [cardsData, setCardsData] = useState([]);
  const [remainingMinutes, setRemainingMinutes] = useState(0);
  const [passedMinutes, setPassedMinutes] = useState(0);

  const refreshInterval = useRef(null);
  const countdownInterval = useRef(null);
  const lastProcessedTimestamp = useRef(null);

  const dispatch = useDispatch();
  const {
    tradingAssistant: {
      getTradeAssistantLoading,
      tradeAssistant,
      aiMarketType,
      allTradeSuggestionData,
    },
    main: { userData },
    profile: { profileData, loading },
    liveSuggestion: { suggestions, status, coinStatus },
    otherCoinsAnalyze: { analyze },
  } = useSelector((state) => state);

  const { minutes_passed, minutes_remaining } = tradeAssistant;
  const stopLossCFC = tradeAssistant?.coefficients?.stop_loss;
  const takeProfitCFC = tradeAssistant?.coefficients?.take_profit;
  const isServiceActive = profileData?.myais_active === "True";

  const symbols = cardsData
    ?.filter((item) => item?.SYMBOL_NAME)
    ?.map((item) => item?.SYMBOL_NAME);

  const liveSuggestionDate = getRelativeTime(
    suggestions?.[0]?.signal_timestamp,
  );

  const handleUpdatePrice = async () => {
    clearInterval(refreshInterval.current);
    clearInterval(countdownInterval.current);
    setPriceRefreshTime(0);

    await axios
      .post(
        "https://tgh7aum9h0.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        {
          platform_mode: "trade_assistant",
          user: userData?.["custom:custom_username"],
          email: userData?.["cognito:username"],
          request_type: "get_price_bulk",
          market_type: "crypto",
          symbol_names: symbols,
        },
      )
      .then((res) => {
        const priceData = res?.data?.PRICE_LIST;
        const priceList = [];
        priceData.map((item) => priceList.push(item?.PRICE));

        setCardsData((prevData) => {
          return prevData.map((item, i) => {
            const tradeType = item?.TRADE_TYPE;
            const priceColor =
              item?.CURRENT_PRICE < priceList[i] ? "#70AF85" : "#EF4B4B";

            return {
              ...item,
              CURRENT_PRICE: priceList[i],
              STOP_LOSS: refreshLimit(
                item.STOP_LOSS_PERCENT * stopLossCFC,
                priceList[i],
                tradeType,
                "stopLoss",
              ),
              TAKE_PROFIT: refreshLimit(
                item.TAKE_PROFIT_PERCENT * takeProfitCFC,
                priceList[i],
                tradeType,
                "takeProfit",
              ),
              priceColor,
            };
          });
        });
        setPriceRefreshTime(60);
      })
      .catch((err) => {
        const { STATUS_CODE, MESSAGE } = err?.response?.data;
        if (MESSAGE) {
          errorHandler(STATUS_CODE, MESSAGE);
        }
        console.log(err);
      });
  };

  useEffect(() => {
    if (data && data.length) {
      const allSignals = [...data, ...allTradeSuggestionData];
      const signalSymbols = suggestions?.map((item) => item?.symbol);

      const matchedSymbols = allSignals
        ?.filter((item) => signalSymbols?.includes(item?.SYMBOL_NAME))
        ?.sort((a, b) => (b?.TRADE_TYPE.includes("Hold") ? 1 : -1))
        ?.sort((a, b) => {
          const aPrediction = suggestions?.find(
            (s) => s?.symbol === `${a?.SYMBOL_NAME}`,
          )?.prediction;
          const bPrediction = suggestions?.find(
            (s) => s?.symbol === `${b?.SYMBOL_NAME}`,
          )?.prediction;
          return bPrediction === "Hold" ? -1 : 1;
        });

      const nonMatchedNonHolds = allSignals?.filter(
        (item) =>
          !signalSymbols?.includes(item?.SYMBOL_NAME) &&
          !item?.TRADE_TYPE?.includes("Hold"),
      );

      const highestHoldSignals = allSignals
        .filter(
          (item) =>
            !signalSymbols?.includes(item?.SYMBOL_NAME) &&
            item.TRADE_TYPE?.includes("Hold"),
        )
        ?.sort((a, b) => b?.SIGNAL_STRENGTH - a?.SIGNAL_STRENGTH);

      setCardsData([
        ...analyze,
        ...matchedSymbols,
        ...nonMatchedNonHolds,
        ...highestHoldSignals,
      ]);
    }
  }, [data, allTradeSuggestionData, analyze, suggestions]);

  // Price refresh effect
  useEffect(() => {
    if (cardsData && cardsData.length && aiMarketType === "crypto") {
      refreshInterval.current = setInterval(handleUpdatePrice, 60 * 1000);
      return () => {
        if (refreshInterval.current) {
          clearInterval(refreshInterval.current);
        }
      };
    }
  }, [cardsData, userData, aiMarketType]);

  // Countdown effect
  useEffect(() => {
    if (cardsData && cardsData.length && aiMarketType === "crypto") {
      countdownInterval.current = setInterval(() => {
        if (priceRefreshTime > 0)
          setPriceRefreshTime((prevTime) => prevTime - 1);
      }, 1000);
    }

    return () => {
      if (countdownInterval.current) {
        clearInterval(countdownInterval.current);
      }
    };
  }, [priceRefreshTime, cardsData, aiMarketType]);

  // Loading state effect
  useEffect(() => {
    if (getTradeAssistantLoading) {
      clearInterval(refreshInterval.current);
      clearInterval(countdownInterval.current);
    }

    if (!getTradeAssistantLoading && tradeAssistant?.ASSISTANT_SUGGESTIONS) {
      setPriceRefreshTime(60);
    }
  }, [getTradeAssistantLoading, tradeAssistant]);

  // Minutes update effect
  useEffect(() => {
    if (tradeAssistant.minutes_remaining) {
      setRemainingMinutes(+minutes_remaining);
      setPassedMinutes(+minutes_passed);
    }
  }, [tradeAssistant, minutes_remaining, minutes_passed]);

  // Interval for minutes countdown
  useEffect(() => {
    if (remainingMinutes > 0) {
      const interval = setInterval(() => {
        setRemainingMinutes((prev) => prev - 1);
        setPassedMinutes((prev) => prev + 1);
      }, 60000);
      return () => clearInterval(interval);
    }
  }, [remainingMinutes]);

  // Live suggestion refresh effect
  useEffect(() => {
    if (!liveSuggestionDate || !suggestions?.length) return;

    if (
      liveSuggestionDate === "15 minutes ago" &&
      lastProcessedTimestamp.current !== liveSuggestionDate
    ) {
      suggestions.forEach((s) => {
        dispatch(
          getLiveSuggestion(
            userData?.["custom:custom_username"],
            userData?.["cognito:username"],
            s?.exchange_id || "kucoin",
            s.symbol,
          ),
        );
      });

      lastProcessedTimestamp.current = getRelativeTime(
        suggestions[0]?.signal_timestamp,
      );
    }
  }, [dispatch, liveSuggestionDate, suggestions, userData]);

  const renderSignalIcon = (prediction) => {
    if (prediction === "Sell")
      return <FaArrowCircleDown size={18} color="#EF4B4B" />;
    if (prediction === "Buy")
      return <FaArrowCircleUp size={18} color="#70AF85" />;
    return <FaPauseCircle size={18} color="#82A0D8" />;
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.countdown}>
          {remainingMinutes === 0 &&
          !getTradeAssistantLoading &&
          tradeAssistant?.minutes_remaining ? (
            <strongSignal>
              <img src={refreshIcon} alt="refresh" />
              Refresh to get new suggestions
            </strongSignal>
          ) : (
            <p>
              <span>
                <MdOutlineUpdate size={16} />
                Next signal:
              </span>
              <span>{remainingMinutes || 0} mins</span>
            </p>
          )}
        </div>
        <AiAssistantTableActionButtons />
      </div>

      <div className={styles.cardsGrid}>
        {getTradeAssistantLoading ? (
          <>
            {[...Array(6)].map((_, index) => (
              <div key={index} className={styles.card}>
                <div className={styles.cardHeader}>
                  <Skeleton.Input style={{ width: 120, height: 24 }} active />
                </div>
                <Skeleton.Button style={{ width: "100%", height: 32 }} active />
                <div className={styles.signalSection}>
                  <div className={styles.signal}>
                    <span className={styles.signalLabel}>1H Signal:</span>
                    <div className={styles.signalInfo}>
                      <Skeleton.Input
                        style={{ width: 200, height: 20 }}
                        active
                      />
                    </div>
                  </div>
                  <div className={styles.signal}>
                    <span className={styles.signalLabel}>15m Signal:</span>
                    <div className={styles.signalInfo}>
                      <Skeleton.Input
                        style={{ width: 200, height: 20 }}
                        active
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.infoSection}>
                  {[...Array(4)].map((_, i) => (
                    <div key={i} className={styles.infoItem}>
                      <Skeleton.Input
                        style={{ width: 80, height: 20 }}
                        active
                      />
                      <Skeleton.Input
                        style={{ width: 100, height: 20 }}
                        active
                      />
                    </div>
                  ))}
                </div>
                <Skeleton.Button style={{ width: "100%", height: 40 }} active />
              </div>
            ))}
          </>
        ) : (
          <>
            <div className={`${styles.card} ${styles.otherCoins}`}>
              <OtherCoins className={styles.otherCoinsBtn} active>
                <h3>
                  <FaCoins />
                  Choose any Coin
                </h3>
                <p>Add new coin from any exchange to see our suggestion!</p>
              </OtherCoins>
            </div>
            {cardsData.map((item, index) => {
              const liveData = suggestions?.find(
                (s) => s?.symbol === item?.SYMBOL_NAME,
              );
              const currentStatus = coinStatus?.[item?.SYMBOL_NAME];
              const prediction =
                liveData?.prediction && liveData?.prediction !== "Hold"
                  ? liveData?.prediction
                  : item?.TRADE_TYPE;

              const bestSignal =
                liveData?.prediction && liveData?.prediction !== "Hold"
                  ? "15m"
                  : "1h";

              return (
                <div key={index} className={styles.card}>
                  <div className={styles.cardHeader}>
                    <AssetDisplay asset={item?.SYMBOL_NAME} />
                  </div>

                  <TradeButton
                    btnClassName={`${
                      isServiceActive
                        ? prediction === "Buy"
                          ? styles.buy
                          : prediction === "Sell"
                            ? styles.sell
                            : styles.hold
                        : styles.hideContent
                    }`}
                    data={{ coin: item?.SYMBOL_NAME }}
                    style={{ textAlign: "center" }}
                    service="aiAssistant"
                    onClick={() => {
                      if (!isServiceActive) {
                        return setShowServiceActivationModal(true);
                      }
                    }}
                  >
                    {!isServiceActive ? (
                      <HideContentIcon style={{ padding: ".5rem" }} />
                    ) : (
                      prediction
                    )}
                  </TradeButton>

                  <div className={styles.signalSection}>
                    <div
                      className={`${styles.signal} ${
                        bestSignal === "1h"
                          ? styles.strongSignal
                          : styles.poorSignal
                      }`}
                    >
                      <span className={styles.signalLabel}>1H Signal:</span>
                      <div
                        className={styles.signalInfo}
                        style={
                          !isServiceActive
                            ? { alignSelf: "flex-end", marginLeft: "auto" }
                            : {}
                        }
                      >
                        {isServiceActive ? (
                          <>
                            <span
                              className={`${styles.signalType} ${
                                styles?.[
                                  `${item?.TRADE_TYPE?.split(
                                    "/",
                                  )?.[0]?.toLowerCase()}Status`
                                ]
                              }`}
                            >
                              {item?.TRADE_TYPE}
                            </span>
                            <span className={styles.confidence}>
                              (Confidence:{" "}
                              {(item?.SIGNAL_STRENGTH * 20).toFixed()}
                              %) -
                            </span>
                            <span className={styles.time}>
                              {60 - remainingMinutes} minutes ago
                            </span>
                          </>
                        ) : (
                          <HideContentIcon
                            clickHandler={(e) => {
                              e.stopPropagation();
                              setShowServiceActivationModal(true);
                            }}
                          />
                        )}
                      </div>
                    </div>

                    <div
                      className={`${styles.signal} ${
                        bestSignal === "15m"
                          ? styles.strongSignal
                          : styles.poorSignal
                      }`}
                    >
                      <span className={styles.signalLabel}>15m Signal:</span>
                      <div
                        className={styles.signalInfo}
                        style={
                          !isServiceActive
                            ? { alignSelf: "flex-end", marginLeft: "auto" }
                            : {}
                        }
                      >
                        {isServiceActive ? (
                          <>
                            {currentStatus === "loading" ? (
                              <Spin size="small" />
                            ) : (
                              <>
                                <span
                                  className={`${styles.signalType} ${
                                    styles?.[
                                      `${
                                        liveData?.prediction?.toLowerCase() ||
                                        "hold"
                                      }Status`
                                    ]
                                  }`}
                                >
                                  {liveData?.prediction || "Hold"}
                                </span>
                                <span className={styles.confidence}>
                                  (Confidence:{" "}
                                  {(liveData?.confidence * 100 || 50).toFixed()}
                                  %) -
                                </span>
                                <span className={styles.time}>
                                  {liveData
                                    ? getRelativeTime(
                                        liveData?.signal_timestamp,
                                      )
                                    : "10 minutes ago"}
                                </span>
                              </>
                            )}
                          </>
                        ) : (
                          <HideContentIcon
                            clickHandler={(e) => {
                              e.stopPropagation();
                              setShowServiceActivationModal(true);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.infoSection}>
                    <div className={styles.category}>
                      <span>Category:</span>
                      <span>{item?.CATEGORY || "Blockchain"}</span>
                    </div>

                    <div className={styles.price}>
                      <span>Current Price:</span>
                      <span>${formatPrice(item?.CURRENT_PRICE, 3)}</span>
                    </div>

                    <div className={styles.stopLoss}>
                      <span>Stop Loss:</span>
                      {isServiceActive ? (
                        <span>
                          {formatPrice(
                            item?.STOP_LOSS_PERCENT * stopLossCFC,
                            2,
                          )}
                          %
                        </span>
                      ) : (
                        <HideContentIcon />
                      )}
                    </div>

                    <div className={styles.takeProfit}>
                      <span>Take Profit:</span>
                      {isServiceActive ? (
                        <span>
                          {formatPrice(
                            item?.TAKE_PROFIT_PERCENT * takeProfitCFC,
                            2,
                          )}
                          %
                        </span>
                      ) : (
                        <HideContentIcon />
                      )}
                    </div>
                  </div>

                  <button
                    className={styles.runAiButton}
                    onClick={() => {
                      if (isServiceActive) {
                        setShowLiveSuggestionModal(true);
                        setLiveSuggestionTarget(item?.SYMBOL_NAME);
                      }
                    }}
                    disabled={!isServiceActive}
                  >
                    Run AI Now
                  </button>
                </div>
              );
            })}
          </>
        )}
      </div>

      <AIAssistantServiceActivationModal
        showActivationModal={showServiceActivationModal}
        setShowActivationModal={setShowServiceActivationModal}
      />

      {showLiveSuggestionModal && (
        <LiveSuggestionModal
          open={showLiveSuggestionModal}
          onClose={() => {
            setShowLiveSuggestionModal(false);
            setLiveSuggestionTarget(null);
          }}
          coin={liveSuggestionTarget}
        />
      )}
    </div>
  );
};

export default TradeAssistantCards;
