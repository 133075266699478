import { InputNumber, Slider, Spin } from "antd";
import styles from "../../../../styles/shared/trade-form/TradeModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import useAssetAmount from "../../hooks/useAssetAmount";
import useCheckPortfolio from "../../hooks/useCheckPortfolio";
import { setTradeSize } from "../../../../redux/trade-form/tradeFormSlice";
import LimitOrderTriggerPrice from "./LimitOrderTriggerPrice";
import SetLimitForBuyOrder from "./SetLimitForBuyOrder";
import ApplyAiSuggestion from "../ApplyAiSuggestion";
import ErrorMessage from "../messages/ErrorMessage";
import useCheckOrderLimits from "../../hooks/useCheckOrderLimits";
import { roundToPrecision } from "../../utils/helper";

function SetTradeAmount() {
  const {
    tradeForm: {
      tradeType,
      tradeNativeAmount = "",
      tradeUsdAmount = "",
      assetPrice,
      assetPriceStatus,
      finalTradeType,
      tradeAmountPercentage,
    },
  } = useSelector((state) => state);

  // const [sliderValue, setSliderValue] = useState(50);
  const [isLimitOrderChecked, setIsLimitOrderChecked] = useState({
    takeProfit: true,
    stopLoss: true,
  });

  const { nativeAmount, usdAmount, isAssetInPortfolio } = useAssetAmount();
  const { budget } = useCheckPortfolio();
  const { amountPrecision } = useCheckOrderLimits();

  const sliderMaximum = 100;
  // const sliderMaximum = tradeType === "sell" ? 100 : 99.99;

  // const maximumNative =
  //   tradeType === "sell" ? nativeAmount : (budget / assetPrice) * 0.9999;
  const maximumNative =
    tradeType === "sell"
      ? roundToPrecision(nativeAmount, amountPrecision)
      : roundToPrecision(budget / assetPrice, amountPrecision);
  // const minimumNative = assetPrice ? 1 / assetPrice : 0.001;
  const maximumUsd =
    tradeType === "buy"
      ? +budget
      : // * 0.9999
        nativeAmount * assetPrice;

  const showLimitSettings =
    finalTradeType !== "sell" && finalTradeType !== "buy";

  const dispatch = useDispatch();

  const changeNativeAmount = (value) => {
    if (
      (tradeType === "sell" && value > Number(nativeAmount)) ||
      (tradeType === "buy" && value > budget / assetPrice)
      // * 0.9999
    )
      return;

    // if (tradeType === "sell") {
    //   setSliderValue((value / nativeAmount) * 100);
    // } else {
    //   setSliderValue(((value * assetPrice) / budget) * 100);
    // }

    const percentage =
      tradeType === "sell"
        ? (value / nativeAmount) * 100
        : ((value * assetPrice) / budget) * 100;

    dispatch(
      setTradeSize(
        roundToPrecision(value, amountPrecision),
        value * assetPrice,
        percentage,
      ),
    );
  };

  const changeUsdAmount = (value) => {
    if (
      (tradeType === "buy" && value > budget) ||
      (tradeType === "sell" && value > usdAmount)
    )
      return;

    // if (tradeType === "sell") {
    //   const percentage = ((value * 100) / nativeAmount) * assetPrice;
    //   setSliderValue(percentage);
    // } else {
    //   const percentage = (value * 100) / budget;
    //   setSliderValue(percentage);
    // }

    const percentage =
      tradeType === "sell"
        ? ((value * 100) / nativeAmount) * assetPrice
        : (value * 100) / budget;

    dispatch(
      setTradeSize(
        roundToPrecision(value / assetPrice, amountPrecision),
        value,
        percentage,
      ),
    );
  };

  const changeSliderValue = (value) => {
    if (!value) {
      return assetPrice
        ? tradeType === "sell"
          ? (100.001 / assetPrice) * nativeAmount
          : 100.001 / budget
        : 0.001;
    } else {
      // setSliderValue(value);
      const percentage = value / 100;
      if (tradeType === "sell") {
        const native = nativeAmount * percentage;
        dispatch(
          setTradeSize(
            roundToPrecision(native, amountPrecision),
            native * assetPrice,
            value,
          ),
        );
      } else {
        const usd = budget * percentage;
        dispatch(
          setTradeSize(
            roundToPrecision(usd / assetPrice, amountPrecision),
            usd,
            value,
          ),
        );
      }
    }
  };

  useEffect(() => {
    if (assetPrice && !tradeNativeAmount && !tradeUsdAmount) {
      const percentage = tradeAmountPercentage / 100;
      // const percentage = sliderValue / 100;
      if (tradeType === "sell") {
        const nativeValue = nativeAmount * percentage;
        dispatch(
          setTradeSize(
            roundToPrecision(nativeValue, amountPrecision),
            nativeValue * assetPrice,
            tradeAmountPercentage,
          ),
        );
      }
      if (tradeType === "buy") {
        const usd = budget * percentage;
        dispatch(
          setTradeSize(
            roundToPrecision(usd / assetPrice, amountPrecision),
            usd,
            tradeAmountPercentage,
          ),
        );
      }
    }
  }, [
    amountPrecision,
    assetPrice,
    budget,
    dispatch,
    nativeAmount,
    tradeAmountPercentage,
    tradeNativeAmount,
    tradeType,
    tradeUsdAmount,
  ]);

  return (
    <div className={styles.setAmountBox}>
      <ApplyAiSuggestion field="tradeAmount" />
      {(tradeType !== "sell" || isAssetInPortfolio) && (
        <>
          <div className={styles.amountInputs}>
            <div className={styles.inputBox}>
              <label htmlFor="amountInput">Native:</label>
              <InputNumber
                value={tradeNativeAmount}
                disabled={!assetPrice}
                max={maximumNative}
                min={0}
                step={0.0001}
                onChange={changeNativeAmount}
              />
            </div>
            <div className={styles.inputBox}>
              <label htmlFor="amountInput">USD:</label>
              <InputNumber
                value={tradeUsdAmount}
                disabled={!assetPrice}
                onChange={changeUsdAmount}
                max={maximumUsd}
                min={0}
                formatter={(value) => `${parseFloat(value)?.toFixed(2)}`}
              />
            </div>
          </div>

          <div className={styles.sliderBox}>
            <Slider
              disabled={!assetPrice}
              value={tradeAmountPercentage}
              onChange={changeSliderValue}
              max={sliderMaximum}
            />
            <InputNumber
              disabled={!assetPrice}
              value={tradeAmountPercentage}
              onChange={changeSliderValue}
              max={sliderMaximum}
              formatter={(value) => `${parseFloat(value)?.toFixed(2)}%`}
              parser={(value) => value.replace("%", "")}
            />
          </div>
        </>
      )}

      {showLimitSettings && (
        <LimitOrderTriggerPrice
          limitType={
            finalTradeType === "sell_sl" || finalTradeType === "buy_db"
              ? "low"
              : finalTradeType === "buy_bb" || finalTradeType === "sell_tp"
                ? "high"
                : ""
          }
        />
      )}

      <SetLimitForBuyOrder
        setLimit={setIsLimitOrderChecked}
        checkedLimit={isLimitOrderChecked}
      />

      {!assetPrice && <div className={styles.overlayMask}></div>}
    </div>
  );
}

export default SetTradeAmount;
