import { useDispatch, useSelector } from "react-redux";
import useAISuggestion from "../hooks/useAISuggestion";
import styles from "../../../styles/shared/trade-form/TradeModal.module.css";
import {
  changeStep,
  placeOrder,
} from "../../../redux/trade-form/tradeFormSlice";
import useCheckOrderLimits from "../hooks/useCheckOrderLimits";
import useAssetAmount from "../hooks/useAssetAmount";
import useCheckPortfolio from "../hooks/useCheckPortfolio";

function TradeHandlers() {
  const {
    tradeForm: { tradeType, finalTradeType, targetExchange, targetCoin, step },
    main: { userData },
  } = useSelector((state) => state);

  const { prediction, positionSize, sl_percent, tp_percent } =
    useAISuggestion();
  const { step1Validation, isTradePermitted } = useCheckOrderLimits();
  const { nativeAmount } = useAssetAmount();
  const { budget } = useCheckPortfolio();

  const enabled =
    targetCoin && step === 1
      ? tradeType && step1Validation
      : step === 2
        ? isTradePermitted
        : "";

  const dispatch = useDispatch();

  if (!tradeType) return null;

  const handleAiBuy = () => {
    dispatch(
      placeOrder(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        "buy_with_oco",
        targetExchange,
        targetCoin,
        positionSize * budget,
        sl_percent,
        tp_percent,
      ),
    );
    return dispatch(changeStep(3));
  };

  const handleQuickSell = () => {
    dispatch(
      placeOrder(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        "sell",
        targetExchange,
        targetCoin,
        nativeAmount,
        "",
      ),
    );
    return dispatch(changeStep(3));
  };

  return (
    <>
      {tradeType === "buy" && prediction && prediction !== "sell" && (
        <span
          className={`${styles.quickOrderBtn} ${!enabled ? styles.disabled : ""}`}
          onClick={handleAiBuy}
        >
          AI Buy Order
        </span>
      )}
      {tradeType === "sell" && (
        <span
          className={`${styles.quickOrderBtn} ${!enabled ? styles.disabled : ""}`}
          onClick={handleQuickSell}
        >
          Quick Sell
        </span>
      )}
    </>
  );
}

export default TradeHandlers;
