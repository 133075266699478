import { InputNumber } from "antd";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  setStopLossTriggerPrice,
  setTakeProfitTriggerPrice,
} from "../../../../redux/trade-form/tradeFormSlice";
import styles from "../../../../styles/shared/trade-form/TradeModal.module.css";
import { AiFillCaretRight } from "react-icons/ai";
import ApplyAiSuggestion from "../ApplyAiSuggestion";
import useCheckOrderLimits from "../../hooks/useCheckOrderLimits";
import { roundToPrecision } from "../../utils/helper";

function LimitOrderTriggerPrice({ limitType, title = true, style = {} }) {
  const {
    tradeForm: {
      targetFee,
      assetPrice,
      assetPriceStatus,
      slTriggerPrice,
      tpTriggerPrice,
    },
  } = useSelector((state) => state);

  const [triggerPrice, setTriggerPrice] = useState();

  const { amountPrecision, pricePrecision } = useCheckOrderLimits();

  const minimumPrice =
    limitType === "high"
      ? roundToPrecision(assetPrice * (1 + targetFee), pricePrecision)
      : roundToPrecision(assetPrice * (1 - 99.99 / 100), pricePrecision);

  const maximumPrice =
    limitType === "high"
      ? roundToPrecision(assetPrice * (1 + 99.99 / 100), pricePrecision)
      : roundToPrecision(assetPrice * (1 - targetFee), pricePrecision);

  const dispatch = useDispatch();

  const disabled = !assetPrice && assetPriceStatus !== "success";

  // const error =
  //   triggerPrice > 0
  //     ? (limitType === "high") &
  //         (triggerPrice < assetPrice * (1 + targetFee)) &&
  //       triggerPrice > assetPrice * (1 + 99.99 / 100)
  //     : limitType === "low" &&
  //       triggerPrice < assetPrice * (1 - 99.99 / 100) &&
  //       triggerPrice > assetPrice * (1 - targetFee);

  const changeInput = (value) => {
    if (!value || Number(value) > maximumPrice || Number(value) < minimumPrice)
      return;

    const adjustedValue = Math.max(value, minimumPrice); // Ensure it never goes negative
    setTriggerPrice(roundToPrecision(adjustedValue, pricePrecision));
    if (limitType === "high") {
      const percentage = value / assetPrice - 1;
      dispatch(setTakeProfitTriggerPrice(percentage));
    } else {
      const percentage = value / assetPrice + 1;
      dispatch(setStopLossTriggerPrice(percentage));
    }

    // dispatch(setLimitOrderTriggerPrice(value));
  };

  // set default trigger price
  useEffect(() => {
    if (assetPrice) {
      if (limitType === "high" && !tpTriggerPrice) {
        const newTriggerPrice = assetPrice * (1 + 2 / 100);
        // dispatch(setLimitOrderTriggerPrice(newTriggerPrice));
        setTriggerPrice(roundToPrecision(newTriggerPrice, pricePrecision));
        dispatch(setTakeProfitTriggerPrice(0.02));
      }

      if (limitType === "low" && !slTriggerPrice) {
        const newTriggerPrice = assetPrice * (1 - 1 / 100);
        // dispatch(setLimitOrderTriggerPrice(newTriggerPrice));
        setTriggerPrice(roundToPrecision(newTriggerPrice, pricePrecision));
        dispatch(setStopLossTriggerPrice(0.01));
      }
    }
  }, [
    limitType,
    assetPrice,
    dispatch,
    slTriggerPrice,
    tpTriggerPrice,
    pricePrecision,
  ]);

  useEffect(() => {
    if (assetPrice) {
      if (limitType === "high" && tpTriggerPrice) {
        const newTriggerPrice = (1 + tpTriggerPrice) * assetPrice;
        setTriggerPrice(roundToPrecision(newTriggerPrice, pricePrecision));
      }

      if (limitType === "low" && slTriggerPrice) {
        const newTriggerPrice = (1 - slTriggerPrice) * assetPrice;
        setTriggerPrice(roundToPrecision(newTriggerPrice, pricePrecision));
      }
    }
  }, [limitType, assetPrice, tpTriggerPrice, slTriggerPrice, pricePrecision]);

  return (
    <div
      className={styles.setAmountBox}
      style={{ marginTop: ".5rem", ...style }}
    >
      {title && (
        <h4>
          <AiFillCaretRight />
          Trigger price
          <ApplyAiSuggestion field={limitType === "high" ? "tp" : "sl"} />
        </h4>
      )}
      <InputNumber
        min={minimumPrice}
        max={maximumPrice}
        onChange={changeInput}
        step={assetPrice / 10000}
        disabled={disabled}
        // value={limitType === "high" ? tpTriggerPrice : slTriggerPrice}
        controls={false}
        value={triggerPrice}
        formatter={(value) => `$${value}`}
        parser={(value) => value.replace("$", "")}
        className={styles.input}
        keyboard={false}
      />
    </div>
  );
}

export default LimitOrderTriggerPrice;
