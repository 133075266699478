import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";
import { IoMdInformationCircleOutline } from "react-icons/io";
import styles from "../../../../styles/shared/trade-form/TradeModal.module.css";
import { setFinalTradeType } from "../../../../redux/trade-form/tradeFormSlice";

const options = {
  buy: {
    label: "Market Buy",
    description: "Buy at the current market price.",
    value: "buy",
  },
  sell: {
    label: "Market Sell",
    description: "Sell at the current market price",
    value: "sell",
  },
};

function InstantOrderOptions() {
  const {
    tradeForm: { tradeType, finalTradeType },
  } = useSelector((state) => state);

  const { label, value, description } = options[tradeType];

  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setFinalTradeType(value));
  };

  return (
    <span
      className={`${styles.tradeTypeBtn} ${finalTradeType === tradeType ? styles.selectedTradeType : ""}`}
      onClick={handleClick}
    >
      {label}
      <Tooltip color="geekblue" title={description}>
        <IoMdInformationCircleOutline />
      </Tooltip>
    </span>
  );
}

export default InstantOrderOptions;
