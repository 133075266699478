import { useDispatch, useSelector } from "react-redux";
import styles from "../../../styles/shared/trade-form/TradeModal.module.css";
import { Spin } from "antd";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { setSelectedPortfolioExchange } from "../../../redux/exchange/actions";
import { resetForm } from "../../../redux/trade-form/tradeFormSlice";
import { IoIosCloseCircle } from "react-icons/io";

const SuggestionBox = () => {
  const { targetExchange } = useSelector((state) => state.tradeForm);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToPortfolio = () => {
    if (targetExchange === "cryptocrispy") {
      navigate("/ai-assistant/portfolio");
    } else {
      dispatch(setSelectedPortfolioExchange(targetExchange));
      navigate("/integration/portfolio");
    }
  };

  const keepTrading = () => dispatch(resetForm());

  return (
    <div className={styles.actionSuggestion}>
      <span className={styles.portfolioBtn} onClick={goToPortfolio}>
        Go to Portfolio
      </span>
      <span className={styles.tradeBtn} onClick={keepTrading}>
        Continue Trading
      </span>
    </div>
  );
};

function OrderResult() {
  const {
    tradeForm: { orderStatus, orderResponse = {}, orderErrorMessage },
  } = useSelector((state) => state);

  return (
    <div className={styles.resultBox}>
      {orderStatus === "loading" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Spin />
          <span>Please Wait...</span>
        </div>
      ) : (
        <>
          {orderResponse?.status_code === 200 ? (
            <div className={styles.successMessage}>
              <FaCheckCircle size={25} color="#2ecc71" />
              <h3>Successfully done.</h3>
              <SuggestionBox />
            </div>
          ) : (
            <div className={styles.successMessage}>
              <IoIosCloseCircle size={30} color="#e74c3c" />
              <h3 style={{ marginBottom: 0 }}>
                {orderErrorMessage?.error || "Something went wrong"}!
              </h3>
              <p>{orderErrorMessage?.description}</p>
              <SuggestionBox />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default OrderResult;
