import styles from "../../../styles/shared/trade-form/TradeModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useRef } from "react";
import {
  getAvailableCoins,
  getIntegratedExchanges,
} from "../../../redux/exchange/actions";
import {
  getTargetPortfolio,
  setTargetExchange,
} from "../../../redux/trade-form/tradeFormSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation } from "react-router-dom";
import { Skeleton, Spin } from "antd";
import { AiFillCaretRight } from "react-icons/ai";
import ErrorMessage from "./messages/ErrorMessage";

function ExchangesList({ defaultExchange = "" }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const controllerRef = useRef(null);

  // Redux state selectors
  const {
    exchangeState: {
      integratedData,
      integratedDataLoading,
      getIntegratedList,
      integratedDataError,
      selectedPortfolioExchange,
      availableCoinsData,
    },
    tradeForm: { targetExchange, targetPortfolioStatus },
    main: { userData },
  } = useSelector((state) => state);

  const exchanges = integratedData?.filter((ex) => ex?.status === "Active");

  const abortPreviousRequests = () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
  };

  const fetchIntegratedExchanges = useCallback(() => {
    if (!userData) return;
    dispatch(
      getIntegratedExchanges(
        userData["custom:custom_username"],
        userData["cognito:username"],
      ),
    );
  }, [dispatch, userData]);

  const handleExchangeSelection = useCallback(
    (exchange) => {
      if (!exchange || targetExchange === exchange.id) return;

      if (targetPortfolioStatus === "loading") {
        abortPreviousRequests();
      }

      dispatch(setTargetExchange(exchange.id));

      controllerRef.current = new AbortController();
      const { signal } = controllerRef.current;

      dispatch(
        getTargetPortfolio(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          exchange.id,
          signal,
        ),
      );
    },
    [dispatch, targetExchange, targetPortfolioStatus, userData],
  );

  const selectFirstExchange = useCallback(() => {
    const firstExchangeId = exchanges?.[0]?.id;

    if (!firstExchangeId) return;

    controllerRef.current = new AbortController();
    const { signal } = controllerRef.current;

    dispatch(setTargetExchange(firstExchangeId));
    dispatch(
      getTargetPortfolio(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        firstExchangeId,
        signal,
      ),
    );
  }, [dispatch, exchanges, userData]);

  useEffect(() => {
    if (
      userData?.["cognito:username"] &&
      !integratedDataLoading &&
      !getIntegratedList &&
      !integratedDataError
    ) {
      fetchIntegratedExchanges();
    }
  }, [
    fetchIntegratedExchanges,
    integratedDataLoading,
    getIntegratedList,
    integratedDataError,
  ]);

  useEffect(() => {
    if (exchanges?.length === 1 && !targetExchange) {
      selectFirstExchange();
    }
  }, [exchanges, targetExchange, selectFirstExchange]);

  useEffect(() => {
    if (!targetExchange) {
      const defaultExchangeId = selectedPortfolioExchange || exchanges?.[0]?.id;

      if (defaultExchangeId) {
        handleExchangeSelection({ id: defaultExchangeId });
      }
    }
  }, [
    targetExchange,
    selectedPortfolioExchange,
    exchanges,
    handleExchangeSelection,
  ]);

  useEffect(() => {
    if (targetExchange && !availableCoinsData?.[targetExchange]) {
      dispatch(getAvailableCoins(targetExchange, true));
    }
  }, [dispatch, targetExchange, availableCoinsData]);

  useEffect(() => {
    if (targetPortfolioStatus === "loading") {
      return () => abortPreviousRequests();
    }
  }, [targetPortfolioStatus]);

  return (
    <>
      <h4 className={styles.title}>
        <AiFillCaretRight />
        Select the exchange that you want to order trade with.
      </h4>
      {integratedDataLoading ? (
        <div className={styles.loader}>
          <Skeleton.Node active />
          <div className={styles.spinner}>
            <Spin size="small" />
            <p>Loading integrated exchanges...</p>
          </div>
        </div>
      ) : integratedDataError ? (
        <ErrorMessage>
          Failed to fetch integrated exchange list!{" "}
          <span className={styles.retryBtn} onClick={fetchIntegratedExchanges}>
            Retry
          </span>
        </ErrorMessage>
      ) : integratedData?.length > 0 ? (
        <ul className={styles.exchangeList}>
          {exchanges?.map((ex) => (
            <li
              key={ex?.id}
              className={`${styles.exchange} ${
                targetExchange === ex?.id ? styles.selectedExchange : ""
              }`}
              onClick={() => handleExchangeSelection(ex)}
            >
              <LazyLoadImage
                src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_exchanges_logo/${ex?.id}.png`}
                alt={ex?.id}
                wrapperClassName={styles.exchangeIcon}
                effect="blur"
              />
              {ex?.name}
            </li>
          ))}
        </ul>
      ) : (
        <p>You don't integrate with any exchange.</p>
      )}
    </>
  );
}

export default ExchangesList;
