import { LuBrainCircuit } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { Button, Empty, message, Tooltip } from "antd";
import { getAISuggestion } from "../../../redux/trade-form/tradeFormSlice";
import useAISuggestion from "../hooks/useAISuggestion";
import styles from "../../../styles/shared/trade-form/TradeModal.module.css";
import React, { useRef } from "react";
import { MdInfo, MdOutlineCancel, MdRefresh } from "react-icons/md";
import { FaBrain } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { getRelativeTime } from "../../../utility/formatter-helper";
import { RxLinkBreak1 } from "react-icons/rx";
import useServiceStatus from "../../../hooks/useServiceStatus";
import { RiLockFill } from "react-icons/ri";

function AiSuggestion() {
  const {
    tradeForm: { targetExchange, targetCoin, tradeType, step },
    main: { userData },
  } = useSelector((state) => state);

  const { isActive } = useServiceStatus("aiAssistant");

  const controllerRef = useRef(null);

  const {
    isLoading,
    tp_percent,
    sl_percent,
    prediction,
    isError,
    error,
    date,
    confidence,
    applyAllSuggestion,
  } = useAISuggestion();
  const disabled = isLoading || !targetExchange || !targetCoin;

  const dispatch = useDispatch();

  const getSuggestion = () => {
    if (!isActive) return message.info("Activate AI service first!");
    if (!targetCoin) return message.info("Please select an asset first");

    // Create a new AbortController
    controllerRef.current = new AbortController();
    const { signal } = controllerRef.current;

    const exchange =
      targetExchange === "cryptocrispy" || targetExchange === "swyftx"
        ? "binance"
        : targetExchange;

    return dispatch(
      getAISuggestion(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        exchange,
        targetCoin,
        false,
        signal,
      ),
    );
  };

  const cancelSuggestion = () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
  };

  // console.log(tp_percent, sl_percent, prediction, isError, error);

  return (
    <div className={styles.suggestionContainer}>
      <div className={styles.suggestionBtns}>
        <Button
          onClick={getSuggestion}
          className={styles.aiBtn}
          loading={isLoading}
          disabled={disabled}
        >
          {/*{!isLoading && <LuBrainCircuit />}*/}
          {!isActive && <RiLockFill />}AI Trade Suggestion
        </Button>

        <div className={styles.suggestionHandlers}>
          {isLoading && (
            <span className={styles.cancelSuggestion}>
              <IoClose onClick={cancelSuggestion} size={16} />
            </span>
          )}
          {!isLoading && (prediction || error) && (
            <span className={styles.retrySuggestion} onClick={getSuggestion}>
              <MdRefresh size={16} color="white" />
            </span>
          )}
        </div>
      </div>

      {error && !isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RxLinkBreak1 size={25} color="#bdc3c7" />
        </div>
      )}
      {/*{error && !isLoading && <Empty className={styles.noSuggestion} />}*/}
      {prediction && (
        <div className={styles.suggestionInfo}>
          <Tooltip
            title={
              <>
                {tradeType !== "sell" && (
                  <>
                    <span>SL: {sl_percent}%, </span>
                    <span>TP: {tp_percent}%, </span>
                  </>
                )}
                <span>Confidence: {confidence}%</span>
              </>
            }
            placement="top"
            color="geekblue"
          >
            <MdInfo color="#0c4cfc" style={{ marginRight: ".2rem" }} />
            <span>{prediction}</span>, <span>{getRelativeTime(date)}</span>
          </Tooltip>
          {step !== 1 && (
            <span
              className={styles.applyAllSuggestion}
              onClick={applyAllSuggestion}
            >
              Apply All
            </span>
          )}
        </div>
      )}

      {/*<LuBrainCircuit className={styles.suggestionIcon} />*/}
      {/*<FaBrain className={styles.suggestionIcon} color="#0c4cfc" size={20} />*/}
    </div>
  );
}

export default AiSuggestion;
