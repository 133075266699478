import { Card } from "antd";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import AssetDisplay from "../../../ui/AssetDisplay";
import styles from "../../../styles/Components/new-insight/chart-section/LiveSuggestionCard.module.css";
import { FaCircleArrowDown, FaCircleArrowUp } from "react-icons/fa6";
import { useMemo, useState } from "react";
import { FaPauseCircle } from "react-icons/fa";
import { MdAccessTime, MdLightbulb, MdTrendingUp } from "react-icons/md";
import { getRelativeTime } from "../../../utility/formatter-helper";
import InfoBox from "../../../shared/live-suggestion/InfoBox";
import Tabs from "../../../shared/live-suggestion/Tabs";
import ChartBox from "../../../shared/live-suggestion/ChartBox";
import HistoryBox from "../../../shared/live-suggestion/HistoryBox";

function LiveSuggestionCard() {
  const {
    liveSuggestion: { suggestions, status, errorMessage, coinStatus },
  } = useSelector((state) => state);

  const [activeTab, setActiveTab] = useState("info");

  const [searchParams] = useSearchParams();
  const symbol = searchParams.get("liveSymbol");

  const liveData = suggestions?.find((s) => s?.symbol === symbol);

  const predictionIcons = useMemo(() => {
    return {
      Hold: <FaPauseCircle size={20} color="#82A0D8" />,
      Buy: <FaCircleArrowUp size={20} color="#70AF85" />,
      Sell: <FaCircleArrowDown size={20} color="#EF4B4B" />,
    };
  }, []);

  const changeTab = (e) => {
    const targetTab = e.target.closest("li");
    if (targetTab) {
      setActiveTab(targetTab.id);
    }
  };

  if (!symbol) return null;

  console.log(liveData);

  return (
    <Card
      title={
        <div className={styles.header}>
          <div className={styles.assetInfo}>
            <span className={styles.assetInfoTitle}>Live Suggestion for</span>{" "}
            <AssetDisplay asset={symbol} className={styles.assetIcon} />
          </div>
          <span>{predictionIcons?.[liveData?.prediction]}</span>
        </div>
      }
      className={styles.card}
    >
      <Tabs
        changeTab={changeTab}
        activeTab={activeTab}
        style={{ marginBottom: "1rem" }}
      />

      {activeTab === "info" && (
        <InfoBox info={liveData} style={{ marginBottom: 0 }} />
      )}

      {activeTab === "chart" && (
        <ChartBox
          data={liveData?.backtest_result?.equity}
          // getData={getHistoryAndChartData}
        />
      )}
      {activeTab === "history" && (
        <HistoryBox
          history={liveData?.backtest_result?.trades}
          // getData={getHistoryAndChartData}
        />
      )}
    </Card>
  );
}

export default LiveSuggestionCard;
